.kdock__brand {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.kdock__brand-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.kdock__brand-content h3 {
    flex: 1;
    font-size: 32px;
    line-height: 48px;
    font-weight: 800;
    font-family: var(--font-family);
    color: var(--color-text);
}

.kdock__brand-content_div {
    display: flex;
    flex-direction: row;
}

.kdock__brand-content_div div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.kdock__brand-content img {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .kdock__brand-content h3{
        font-size: 24px;
        line-height: 32px;
    }
    
    .kdock__brand-content_div {
        flex-direction: column;
    }
}