.kdock__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.kdock__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.kdock__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.kdock__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.kdock__blog-container_article-content p {
    font-size: 12px;
    line-height: 35px;
    font-weight: bold;
    font-family: var(--font-family);
    color: #fff
}

.kdock__blog-container_article-content h3 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 800;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 2rem;
    cursor: pointer;
}

.kdock__blog-container_article-content h4 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    color: var(--color-text);

    margin-bottom: 2rem;
}

.kdock__blog-container_article-content p:last-child {
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .kdock__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}